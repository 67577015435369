import Component from '../../libs/component';
import scope from '../../scope';

scope.components['highlight'] = Component.extend({
    name: 'highlight',
    ui: {
        list: '.highlight__list '
    },
    events: {
        'initialize.carousel @ui.list': 'initCarousel'
    },
    initCarousel: function(e, data){
        data.options.autoPlay = 30000;
    }
});

export default scope.components['highlight'];

