import Component from '../../libs/component';
import FormRelease from '../../behaviors/FormRelease';
import FormReveal from '../../behaviors/FormReveal';
import scope from '../../scope';
import $ from 'jquery';

scope.components['search'] = Component.extend({
    name: 'search',
    ui: {
    },
    events: {
    },
    childViewEvents: {
        'form:success': 'showSuccessMessage',
        'form:error': 'showErrorMessage',
    },
    behaviors: {
        "FormRelease": FormRelease,
        "FormReveal": FormReveal
    },
    showSuccessMessage: function(){
        console.log('Busca ok', this);
        console.log(arguments);
    },
    showErrorMessage: function(){
        console.log('Busca com erro', this);
        console.log(arguments);
    },
    onAttach: function(){
        console.log('da o attach search');
    },
    onRender: function(){
        console.log('da o render search');
    },
    changeComponentContent: function(){
        //ignora respostas de alteracoes do servidor
    },
});

export default scope.components['search'];

