import { Behavior } from 'backbone.marionette';
import $ from 'jquery';

var FormRelease = Behavior.extend({
    ui: {
        formRelease: '[data-release]:not(button,a)',
        formReleaseButton: 'button[data-release],a[data-release]',
    },
    events: {
        'change @ui.formRelease': 'releaseTargets',
        'click @ui.formReleaseButton': 'releaseButton',
    },
    onRender: function () {
        this.onAttach();
    },
    onViewAttach: function(){
        this.onAttach();
    },
    onComponentChange: function(){
        this.onAttach();
    },
    onAttach: function () {
        var self = this;

        console.log('release:attach');

        self.ui.formRelease.filter('input:checked,select').each(function(){
            self.releaseTargets({
                currentTarget: this
            })
        })

        self.ui.formReleaseButton.filter('[data-release-active]').each(function(){
            self.releaseButton({
                currentTarget: this
            })
        })
    },
    releaseTargets: function(e) {
        var el = $(e.currentTarget);
        var value = el.val();
        var form = el.parents('form');
        var selectCondition = el.data('selectCondition');
        var target = selectCondition ? el.data('selectTarget') : el.attr('name') || el.attr('data-release');
        var targets = form.find('[data-release-listener="' + target + '"]');

        if (el.filter('input[type=checkbox],input[type=radio]').length > 0 || selectCondition) {
            targets.attr('disabled', 'disabled');

            if (el.is(":checked") || selectCondition == value) {
                targets.filter('[data-release-value="checked"]').removeAttr('disabled');
                targets.filter('[data-release-value~="' + value + '"]').removeAttr('disabled');
                targets.filter(':not([data-release-value])').removeAttr('disabled');
            } else {
                targets.filter('[data-release-value="unchecked"]').removeAttr('disabled');
                targets.filter('[data-release-value~="' + value + '"]').attr('disabled', 'disabled');
                targets.filter(':not([data-release-value])').attr('disabled', 'disabled');
            }
        } else {
            targets.attr('disabled', 'disabled');
            targets.filter('[data-release-value~="' + value + '"]').removeAttr('disabled');
            targets.filter(':not([data-release-value])').removeAttr('disabled');
        }
    },
    releaseButton: function(e){
        var el = $(e.currentTarget);
        var value = el.val();
        var form = el.parents('form');
        var selectCondition = el.data('selectCondition');
        var target = selectCondition ? el.data('selectTarget') : el.attr('name') || el.attr('data-release');
        var targets = form.find('[data-release-listener="' + target + '"]');

        if (el.filter('button,a').length > 0 || selectCondition) {
            targets.attr('disabled', 'disabled');
            targets.filter('[data-release-value="' + value + '"]').removeAttr('disabled');
            targets.filter(':not([data-release-value])').removeAttr('disabled');
        }
    }
});

export default FormRelease;
